<template>
  <span class="common_arrow" :style="{'height': `${height}px`, 'width': `${width}px`}" />
</template>

<script>
export default {
  name: 'IconClose',

  props: {
    width: {
      type: Number,
      default: 8
    },

    height: {
      type: Number,
      default: 14
    }
  }
}
</script>

<style lang="scss" scoped>
  .common_arrow { display: inline-block; vertical-align: middle; background-image: url(~@/assets/images/icon_callback.png); background-size: 100% 100%; }
</style>
