<template>
  <div class="customization">
    <div class="customization_header">
      <div class="title_box">
        <div class="callback" @click="goBack"><IconArrow /><span class="font_word">返回 </span></div>
        <div class="title_content">
          <p class="header_title">PPT专业定制</p>
        </div>
      </div>
    </div>

    <div class="customization_content">
      <div class="logo_list">
        <div v-for="logo in logoList" :key="logo.title" class="logo_container">
          <div :class="['logo', logo.className]" />
          <p class="logo_title">{{ logo.title }}</p>
        </div>
      </div>

      <div class="form">
        <div class="form_item">
          <span class="form_item_title">定制内容</span>
          <div class="form_item_content">
            <div v-for="text in contentList" :key="text" class="cus_content_container">
              <Checkbox
                :status="form.content === text"
                @select="clickFromContent(text)" />
              <span :class="['text', { other: text === '其他' }]">{{ text }}</span>
              <input
                v-if="text === '其他'" v-model="contentOther" class="input"
                @input="selectOther">
            </div>
          </div>
        </div>

        <div class="form_item">
          <span class="form_item_title">需求概述</span>
          <div class="form_item_content">
            <textarea
              v-model="form.requirement" class="textarea"
              placeholder="请简要描述您的定制需求（选填）" maxlength="200" />
          </div>
        </div>

        <div class="form_item">
          <span class="form_item_title">联系电话</span>
          <div class="form_item_content">
            <input v-model="form.phone" class="input">
          </div>
        </div>

        <div class="form_item">
          <div v-report:mainclick="{ area: 'enquire', pos: '0' }" class="button" @click="clickSubmit">获取报价</div>
        </div>
      </div>
    </div>

    <DialogC v-model="dialogShow" size="mini">
      <div class="dialog_content">
        <span class="dialog_content_text">报价评估中，我们将于24小时内与您联系</span>
        <div class="button" @click="closeDialog">好的</div>
      </div>
    </DialogC>
  </div>
</template>

<script>
import api from '@/api/index.js'
import IconArrow from '@/components/Icons/IconArrow.vue'
import DialogC from '@/components/DialogC/index.vue'
import Checkbox from '@/components/Checkbox/index.vue'

export default {
  name: 'Customization',

  components: { IconArrow, DialogC, Checkbox },

  data () {
    return {
      dialogShow: false,
      dialogContent: '',
      form: {
        content: '总结汇报',
        requirement: '',
        phone: ''
      },
      contentOther: '',
    }
  },

  computed: {
    logoList () {
      return [
        {
          className: 'customization_1',
          title: '一对一服务'
        },
        {
          className: 'customization_2',
          title: '快速交稿'
        },
        {
          className: 'customization_3',
          title: '信息保密'
        },
        {
          className: 'customization_4',
          title: '免费修改'
        },
        {
          className: 'customization_5',
          title: '正规发票'
        }
      ]
    },
    contentList () {
      return ['总结汇报', '教学课件', '党政宣传', '策划案', '企业宣传', '毕业答辩', '产品介绍', '奖项申报', '其他']
    }
  },

  methods: {
    /** 点击-选择定制内容 **/
    clickFromContent (text) {
      // 与之前内容相同，证明取消了勾选
      if (text === this.form.content) {
        this.form.content = ''
      } else {
        this.form.content = text
      }
    },

    /** 点击-提交 **/
    clickSubmit () {
      const params = this.getParamsByForm()
      if (!this.isCorrectParams(params)) {
        return
      }

      this.submit(params).then(() => {
        this.showDialog()
      })
    },

    closeDialog () {
      this.dialogShow = false
    },

    showDialog () {
      this.dialogShow = true
    },

    selectOther () {
      this.form.content = '其他'
    },

    getParamsByForm () {
      let { content, requirement, phone } = this.form

      if (content === '其他') {
        content = this.contentOther
      }

      return { data: { content, requirement, phone } }
    },

    submit (params) {
      return api.material.customization(params)
    },

    isCorrectParams (params) {
      const { content, phone } = params.data
      if (this.form.content === '') {
        this.$toast({ desc: '请勾选定制内容' })
        return false
      }

      if (this.form.content === '其他' && content === '') {
        this.$toast({ desc: '请填写其他定制内容' })
        return false
      }

      if (phone === '') {
        this.$toast({ desc: '请填写联系电话' })
        return false
      }

      if (!this.isPhoneNumber(phone)) {
        this.$toast({ desc: '手机号格式有误，请重新填写' })
        return false
      }

      return true
    },

    isPhoneNumber (phoneNum) {
      const reg = /(^|\s*\+?0?0?86|\D)(1\d{2})[-\s]{0,3}(\d{4})[-\s]{0,3}(\d{4})(?=\D|$)/
      return reg.test(phoneNum)
    },

    goBack () {
      this.$router.push(`/${this.$route.query.from}?from=customization`)
    },
  }
}
</script>

<style lang="scss" scoped>
.customization { position: absolute; top: 0; right: 0; bottom: 0; left: 70px; display: flex; flex-direction: column;
  &_header { display: flex; align-items: center; justify-content: space-between; height: 39px; padding: 0 16px; background-color: #fff; border-bottom: 1px solid #dedede; }
  .title_box { display: flex; flex: 1; margin-right: 50px;
    .callback { margin-right: 50px; font-size: 14px;; line-height: 14px; cursor: pointer;
      .font_word { margin-left: 8px; font-weight: 400; color: #666; vertical-align: middle; }
    }

    .title_content { display: flex; flex: 1; justify-content: center;
      .header_title { display: flex; align-items: center; font-size: 16px; font-weight: 400; color: #333; }
    }
  }

  &_content { font-family: Microsoft YaHei; font-size: 14px; font-weight: 400;
    .logo_list { display: flex; justify-content: space-between; width: 530px; margin: 32px auto 0 auto;
      .logo_container { display: flex; flex-direction: column; align-items: center; width: 70px;
        .logo { width: 50px; height: 50px;
          &.customization_1 { background-image: url(~@/assets/images/icon_customization1.png); }
          &.customization_2 { background-image: url(~@/assets/images/icon_customization2.png); }
          &.customization_3 { background-image: url(~@/assets/images/icon_customization3.png); }
          &.customization_4 { background-image: url(~@/assets/images/icon_customization4.png); }
          &.customization_5 { background-image: url(~@/assets/images/icon_customization5.png); }
        }
        .logo_title { margin-top: 11px; font-family: Microsoft YaHei; font-size: 14px; font-weight: 400; color: #666666; }
      }
    }
    .form { width: 536px; margin: 51px auto 0 auto;
      .form_item { display: flex; margin-bottom: 26px;
        &_title { display: inline-block; margin-right: 42px; font-size: 16px; font-weight: bold; color: #333333; }
        &_content { display: flex; flex-wrap: wrap; justify-content: space-between; width: 430px;
          .cus_content_container { display: flex; align-items: center; width: 100px; }
          .cus_content_container:last-child { width: 430px; }
          .cus_content_container:nth-child(n+5) { margin-top: 27px; }
          .input { width: 300px; height: 32px; font-family: Microsoft YaHei; background: #F7F9F9; border: 1px solid #999999; border-radius: 2px; outline: none; }
          .textarea { box-sizing: border-box; width: 300px; height: 100px; padding: 5px; font-family: Microsoft YaHei; color: #333333; resize: none; background: #F7F9F9; border: 1px solid #999999; border-radius: 2px; outline: none; }
          .text { color: #333333;
            &.other { margin-right: 17px; }
          }
        }
        .button { display: flex; align-items: center; justify-content: center; width: 240px; height: 50px; margin: 15px auto 0 auto; font-size: 16px; color: #FFFFFF; background: linear-gradient(-55deg, #3998FF, #7EBCFF); border-radius: 25px; }
        .button:hover { cursor: pointer; background: linear-gradient(-55deg, #61ADFF, #96C9FF); }
      }
    }
  }

  .dialog_content { display: flex; flex-direction: column; align-items: center; justify-content: center;
    .button { display: flex; align-items: center; justify-content: center; width: 82px; height: 30px; margin-top: 35px; font-size: 12px; font-weight: 400; color: #4A96E8; background: #FFFFFF; border: 1px solid #4A96E8; border-radius: 4px; }
    .button:hover { color: #FFFFFF; cursor: pointer; background: #4A96E8; }
  }
  .dialog_content_text { margin-top: 40px; font-size: 14px; font-weight: 400; color: #444444; }
}
</style>
